// card 
.card{
    border-color: var(--border-color);
    background-color: var(--card-color);
    &.card-callout{
        border-left-width: .4rem;
        border-left-color: var(--primary-color);
    }
    .card-header{
        background-color: transparent;
        border-bottom: none;
    }
}

.card-header{
    h3{
        @media only screen and (max-width: 1024px){
            font-size: 20px;
        }
        @media only screen and (max-width: 767px){
            font-size: 18px;  
        }
    }
}